import React, { useState, useEffect } from "react";
import "./Model.css";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "../admin/AdminPanel";
import "firebase/compat/database"; // Import Realtime Database

const Model = (props) => {
  const { model } = props;
  const [carParts, setCarParts] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // New state for search input
  const [enlargedCard, setEnlargedCard] = useState(null); // State for enlarged card

  useEffect(() => {
    // Initialize Firebase if not already initialized
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    fetchCarParts();
  }, []);

  const fetchCarParts = async () => {
    try {
      const snapshot = await firebase.firestore().collection("Lparts").get();
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      if (!data) {
        setCarParts([]);
        return;
      }

      // Convert the data object into an array
      const partsArray = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));
      setCarParts(partsArray);
    } catch (error) {
      console.error("Failed to fetch car parts:", error);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleCardClick = (part) => {
    setEnlargedCard(part);
  };

  const handleClose = () => {
    setEnlargedCard(null);
  };

  return (
    <div className="container">
      <h2 className="header">ავტონაწილები</h2>
      {/* Search bar */}
      <div className="search-bar">
        <input
          type="text"
          value={searchInput}
          onChange={handleSearchInputChange}
          placeholder="ძიება..."
        />
      </div>
      <div className="cards">
        {carParts
          .filter((part) => part.name.includes(searchInput)) // Filter the parts based on the search input
          .map((part) =>
            part.model === `${model}` ? (
              <div
                key={part.id}
                className={`part-card ${part.stock ? 'in-stock' : 'out-of-stock'}`}
                onClick={() => handleCardClick(part)} // Handle click event
              >
                <div className="card-image-wrapper">
                  <img
                    src={part.image_url}
                    alt={part.name}
                    className="card-image"
                  />
                </div>
                <div className="card-text">
                  <p className="card-title">{part.name}</p>
                  <p>მოდელი: {part.model}</p>
                  <p className="card-price">ფასი: {part.price}</p>
                  <p className={`stock ${part.stock ? 'in-stock-text' : 'out-of-stock-text'}`}>
                    {part.stock ? 'მარაგშია' : 'არ არის მარაგში'}
                  </p>
                </div>
              </div>
            ) : null
          )}
      </div>
      {/* {enlargedCard && (
        <EnlargedCard part={enlargedCard} onClose={handleClose} />
      )}{" "} */}
    </div>
  );
};

export default Model;
