import React from "react";
import "./Footer.css";
import viber from "../../assets/viber.webp";
import whatsapp from "../../assets/whatsapp.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="contact-info-container">
        <div className="contact-info">
          <p>ტელ:</p>
          <p>
            <a href="tel:+995 577 14 85 06">577 14 85 06</a>
          </p>
          <p>
            <a href="tel:+995 598 21 21 40">598 21 21 40</a>
          </p>
        </div>
        <div className="logo-container">
          <a
            href="https://api.whatsapp.com/send?phone=+33605528442&text=Hello!"
            target="_blank"
          >
            <img src={whatsapp} alt="Logo" className="whatsapp" />
          </a>
          <a href="viber://contact?number=%2B995577148506" target="_blank">
            <img src={viber} alt="Logo" className="viber" />
          </a>
        </div>
      </div>

      <div className="addres-container">
        <a
          href="https://goo.gl/maps/YR6Q7d5qc9GLGHyN7"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="address">ქ. გორი, ჟ. შარტავას #108</p>
        </a>

        <p>ვაგზავნით რეგიონებშიც!</p>
      </div>
    </footer>
  );
};

export default Footer;
