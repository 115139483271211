import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";

import Admin from "./components/admin/Admin";

import MainPage from "./components/MainPage/MainPage";
import Brand from "./components/brand/Brand";

import Model from "./components/model/Model";
import Footer from "./components/MainPage/Footer";
import { models } from "./Data";
import Logo from "./components/MainPage/Logo";

const App = () => {
  return (
    <div>
      <Router>
        <Link to="/">
          <Logo />
        </Link>

        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/admin" element={<Admin />} />

          {models.map((brand, index) => (
            <Route
              key={index}
              path={`/${brand.brandName}`}
              element={
                <Brand brandName={brand.brandName} carModels={brand.models} />
              }
            />
          ))}

          <Route
            path="/Tesla/model3"
            element={<Model model="Model 3 2015 - 2023" />}
          />

          <Route
            path="/Hyundai/Elantra 2019 - 2020"
            element={<Model model="Elantra 2019 - 2020" />}
          />
          <Route
            path="/Hyundai/Elantra 2016 - 2018"
            element={<Model model="Elantra 2016 - 2018" />}
          />
          <Route
            path="/Hyundai/Elantra 2021 - 2023"
            element={<Model model="Elantra 2021 - 2023" />}
          />
          <Route
            path="/Hyundai/Kona 2016 - 2022"
            element={<Model model="Kona 2016 - 2022" />}
          />
          <Route
            path="/Hyundai/Sonata 2015 - 2017"
            element={<Model model="Sonata 2015 - 2017" />}
          />
          <Route
            path="/Hyundai/Sonata 2018 - 2019"
            element={<Model model="Sonata 2018 - 2019" />}
          />
          <Route
            path="/Hyundai/Tucson 2016 - 2018"
            element={<Model model="Tucson 2016 - 2018" />}
          />
          <Route
            path="/Hyundai/Tucson 2019 - 2021"
            element={<Model model="Tucson 2019 - 2021" />}
          />

          <Route
            path="/Toyota/Prius 2010 - 2015"
            element={<Model model="Prius 2010 - 2015" />}
          />
          <Route
            path="/Lexus/Lexus RX 2010 - 2015"
            element={<Model model="Lexus RX 2010 - 2015" />}
          />
          <Route
            path="/Lexus/Lexus RX 2016 - 2020"
            element={<Model model="Lexus RX 2016 - 2020" />}
          />
          <Route
            path="/Lexus/Lexus RX 2021 - 2023"
            element={<Model model="Lexus RX 2021 - 2023" />}
          />
          <Route
            path="/Lexus/Lexus NX 2017 - 2021"
            element={<Model model="Lexus NX 2017 - 2021" />}
          />
          <Route
            path="/Lexus/Lexus NX 2022 - 2023"
            element={<Model model="Lexus NX 2022 - 2023" />}
          />
          <Route path="/Lexus/Lexus UX" element={<Model model="Lexus UX" />} />
          <Route path="/Lexus/Lexus GX" element={<Model model="Lexus GX" />} />
          <Route
            path="/Kia/Optima 2016 - 2018"
            element={<Model model="Optima 2016 - 2018" />}
          />
          <Route
            path="/Kia/Optima 2019 - 2020"
            element={<Model model="Optima 2019 - 2020" />}
          />
          <Route
            path="/Kia/Forte 2019 - 2020"
            element={<Model model="Forte 2019 - 2020" />}
          />
          <Route
            path="/Tesla/Model 3 2015 - 2023"
            element={<Model model="Model 3 2015 - 2023" />}
          />
          <Route
            path="/Tesla/Model Y 2020 - 2024"
            element={<Model model="Model Y 2020 - 2024" />}
          />
          <Route
            path="/Tesla/Model S 2013 - 2024"
            element={<Model model="Model S 2013 - 2024" />}
          />
          <Route
            path="/Tesla/Model X 2016 - 2024"
            element={<Model model="Model X 2016 - 2024" />}
          />
          <Route path="/Jeep/Renegade" element={<Model model="Renegade" />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
};

export default App;
