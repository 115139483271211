import { useState } from "react";
import { Navigate } from "react-router-dom";
import AdminPanel from "./AdminPanel";
import "./Admin.css";
const Admin = () => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div>
      {password === "ninigio" ? (
        <AdminPanel />
      ) : (
        <form className="adminForm">
          <input
            className="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <button>Login</button>
        </form>
      )}
    </div>
  );
};

export default Admin;
