import Elantra2017 from "./assets/179be44dd7a6ef5633f190546df38669ea11e971.avif";
import Elantra2019 from "./assets/2019-Hyundai-Elantra-front_13179_032_2400x1800_W8.avif";
import Elantra2022 from "./assets/2022-elantra-hev-1_16-9.webp";
import Kona from "./assets/2021-Hyundai-Kona-front_14248_032_2400x1800_P6W.webp";
import Sonata2017 from "./assets/2017-hyundai-sonata-hybrid-limited-sedan-angular-front.avif";
import Sonata2019 from "./assets/2019-Hyundai-Sonata-front_12093_032_2400x1800_W8.webp";
import Prius2015 from "./assets/2015-toyota-prius-5dr-hb-three-natl-angular-front-exterior-view_100485217_h.jpg";
import Lexus_RX_2015 from "./assets/2015-Lexus-RX-front_9677_032_2400x1800_083.webp";
import Lexus_RX_2020 from "./assets/a1c31b3f-fa6d-46a6-8601-2fc4652adf52.webp";
import Lexus_RX_2023 from "./assets/02a0350.jpg";
import Lexus_NX_2021 from "./assets/2021-Lexus-NX-front_13730_032_2400x1800_083.webp";
import Lexus_NX_2023 from "./assets/2023-Lexus-NX-front_52464_032_2400x1800_083.avif";
import Lexus_UX from "./assets/2022-Lexus-UX-front_50634_032_2400x1800_083.webp";
import Lexus_GX from "./assets/9ea9d680d22ec6270a84f254d0d49a63x.webp";
import Optima2018 from "./assets/1.webp";
import Optima2020 from "./assets/2020-Kia-Optima-front_13017_032_2400x1800_SWP.webp";
import Forte2020 from "./assets/2020-Kia-Forte-front_13602_032_2400x1800_SWP.webp";
import Tesla_Model_3 from "./assets/6153cdf8aec0a73b65af24c0_tesla-model-3.png";
import Renegade from "./assets/2020-jeep-renegade-latitude-suv-angular-front.webp";
import Sonata2015 from "./assets/sonata2015.avif";
import Tucson2016 from "./assets/tucson2016.avif";
import Tucson2019 from "./assets/tucson2019.avif";
import Model_S from "./assets/model s.avif";
import Model_Y from "./assets/model y.png";
import Model_x from "./assets/model x.webp";
export const models = [
  {
    brandName: "Hyundai",
    models: [
      {
        modelName: "Elantra 2016 - 2018",
        image: Elantra2017,
      },
      {
        modelName: "Elantra 2019 - 2020",
        image: Elantra2019,
      },
      {
        modelName: "Elantra 2021 - 2023",

        image: Elantra2022,
      },
      {
        modelName: "Kona 2016 - 2022",

        image: Kona,
      },
      {
        modelName: "Sonata 2015 - 2017",

        image: Sonata2015,
      },
      {
        modelName: "Sonata 2018 - 2019",

        image: Sonata2017,
      },
      {
        modelName: "Tucson 2016 - 2018",

        image: Tucson2016,
      },
      {
        modelName: "Tucson 2019 - 2021",

        image: Tucson2019,
      },
    ],
  },
  {
    brandName: "Toyota",
    models: [
      {
        modelName: "Prius 2010 - 2015",

        image: Prius2015,
      },
    ],
  },
  {
    brandName: "Lexus",
    models: [
      {
        modelName: "Lexus RX 2010 - 2015",

        image: Lexus_RX_2015,
      },
      {
        modelName: "Lexus RX 2016 - 2020",

        image: Lexus_RX_2020,
      },
      {
        modelName: "Lexus RX 2021 - 2023",

        image: Lexus_RX_2023,
      },
      {
        modelName: "Lexus NX 2017 - 2021",

        image: Lexus_NX_2021,
      },
      {
        modelName: "Lexus NX 2022 - 2023",

        image: Lexus_NX_2023,
      },
      {
        modelName: "Lexus UX",

        image: Lexus_UX,
      },
      {
        modelName: "Lexus GX",

        image: Lexus_GX,
      },
    ],
  },
  {
    brandName: "Kia",
    models: [
      {
        modelName: "Optima 2016 - 2018",

        image: Optima2018,
      },
      {
        modelName: "Optima 2019 - 2020",

        image: Optima2020,
      },
      {
        modelName: "Forte 2019 - 2020",

        image: Forte2020,
      },
    ],
  },
  {
    brandName: "Tesla",
    models: [
      {
        modelName: "Model 3 2015 - 2023",

        image: Tesla_Model_3,
      },
      {
        modelName: "Model Y 2020 - 2024",

        image: Model_Y,
      },
      {
        modelName: "Model S 2013 - 2024",

        image: Model_S,
      },
      {
        modelName: "Model X 2016 - 2024",

        image: Model_x,
      },
      
      
    ],
  },
  {
    brandName: "Jeep",
    models: [
      {
        modelName: "Renegade",

        image: Renegade,
      },
    ],
  },
];
