import React from "react";
import "./MainPage.css";
import { Link } from "react-router-dom";

import toyotalogo from "../../assets/toyotal.webp";
import hyundailogo from "../../assets/hyundail.gif";

const MainPage = () => {
  return (
    <div className="main-page">
      <header className="header">
        {/* <Logo className="logo" /> */}
        <h1 className="header_text">ავტონაწილები საუკეთესო ფასად!</h1>
      </header>

      <main className="main">
        <Link to="/Toyota">
          <div className="card">
            <img src={toyotalogo} alt="Card Image" className="toyota" />
          </div>
        </Link>
        <Link to="/Hyundai">
          <div className="card">
            <img src={hyundailogo} alt="Card Image" className="hyundai" />
          </div>
        </Link>
        <Link to="/Kia">
          <div className="card">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/1/13/Kia-logo.png"
              alt="Card Image"
              className="kia"
            />
          </div>
        </Link>
        <Link to="/Lexus">
          <div className="card">
            <img
              src="https://www.carlogos.org/logo/Lexus-symbol-1988-1920x1080.png"
              alt="Card Image"
              className="lexus"
            />
          </div>
        </Link>
        <Link to="/Tesla">
          <div className="card">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png"
              alt="Card Image"
              className="tesla"
            />
          </div>
        </Link>
        <Link to="/Jeep">
          <div className="card">
            <img
              src="https://1000logos.net/wp-content/uploads/2018/04/Jeep-Logo-1970-1.png"
              alt="Card Image"
              className="Jeep"
            />
          </div>
        </Link>
      </main>
    </div>
  );
};

export default MainPage;
