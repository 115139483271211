// Brand.js (Brand component)
import React from "react";
import "./Brand.css";
import { Link } from "react-router-dom";

const Brand = ({ brandName, carModels }) => {
  return (
    <div className="brand_container">
      <h1>აირჩიეთ სასურველი მოდელი</h1>
      <div className="cards_container">
        {carModels.map((model, index) => (
          <Link key={index} to={`/${brandName}/${model.modelName}`}>
            <div className="brand_card">
              {/* Render the image */}
              <img src={model.image} alt={model.modelName} />

              {/* Render other information for the card */}
              <p>{model.modelName}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Brand;
