import React, { useState, useEffect } from "react";
import "./AdminPanel.css";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyBrZ6EMetW0A0omzlHeKr_LmkG3dFOdVVw",
  authDomain: "lparts-faa98.firebaseapp.com",
  projectId: "lparts-faa98",
  storageBucket: "lparts-faa98.appspot.com",
  messagingSenderId: "835242652207",
  appId: "1:835242652207:web:f66699d8dd3837b73d0cda",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();
const firestore = firebase.firestore();

export const carModels = {
  Kia: ["Optima 2016 - 2018", "Optima 2019 - 2020", "Forte 2019 - 2020"],
  Toyota: ["Prius 2010 - 2015"],
  Hyundai: [
    "Elantra 2016 - 2018",
    "Elantra 2019 - 2020",
    "Elantra 2021 - 2023",
    "Kona 2016 - 2022",
    "Sonata 2015 - 2017",
    "Sonata 2018 - 2019",
    "Tucson 2016 - 2018",
    "Tucson 2019 - 2021",
  ],
  Lexus: [
    "Lexus RX 2010 - 2015",
    "Lexus RX 2016 - 2020",
    "Lexus RX 2021 - 2023",
    "Lexus NX 2017 - 2021",
    "Lexus NX 2022 - 2023",
    "Lexus UX",
    "Lexus GX",
  ],
  Tesla: ["Model 3 2015 - 2023", "Model Y 2020 - 2024", "Model S 2013 - 2024", "Model X 2016 - 2024"],
  Jeep: ["Renegade"],
};

function AdminPanel() {
  const [parts, setParts] = useState([]);
  const [image, setImage] = useState(null);
  const [part, setPart] = useState({
    name: "",
    image_url: "",
    price: "",
    brand: "",
    model: "",
    year: "",
    stock: true,
    id: null, // Add an ID field to identify part for update
  });

  const handleChange = (event) => {
    setPart((prevPart) => ({
      ...prevPart,
      [event.target.name]: event.target.value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    try {
      let imageUrl = part.image_url;

      if (image) {
        // Upload image if a new one is selected
        const imageRef = storage.ref(`images/${uuidv4()}-${image.name}`);
        const snapshot = await imageRef.put(image);
        imageUrl = await snapshot.ref.getDownloadURL();
      }

      const { name, price, brand, model, year, stock, id } = part;

      if (id) {
        await firestore.collection("Lparts").doc(id).update({
          name,
          image_url: imageUrl,
          price,
          brand,
          model,
          year,
          stock,
        });
        setParts((prevParts) =>
          prevParts.map((p) =>
            p.id === id
              ? { ...p, name, image_url: imageUrl, price, brand, model, year, stock }
              : p
          )
        );
      } else {
        const newPartRef = await firestore.collection("Lparts").add({
          name,
          image_url: imageUrl,
          price,
          brand,
          model,
          year,
        stock,
        });
        setParts([...parts, { id: newPartRef.id, ...part }]);
      }

      setPart({
        name: "",
        image_url: "",
        price: "",
        brand: "",
        model: "",
        year: "",
        stock: true,
        id: null, // Reset ID field
      });
      setImage(null); // Clear the selected image
    } catch (error) {
      console.error("Error uploading image or data:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = firestore
      .collection("Lparts")
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort the data based on the 'model' field in ascending order
        data.sort((a, b) => (a.model > b.model ? 1 : -1));

        setParts(data);
      });

    return () => unsubscribe();
  }, []);

  const deletePart = async (partToDelete) => {
    try {
      await firestore.collection("Lparts").doc(partToDelete.id).delete();
      setParts((prevParts) => prevParts.filter((part) => part.id !== partToDelete.id));
    } catch (error) {
      console.error("Error deleting part:", error);
    }
  };

  const updatePart = (partToUpdate) => {
    setPart({ ...partToUpdate });
  };

  return (
    <div className="container-panel">
      <form className="part-form" onSubmit={handleUpload}>
        <label>
          სახელი
          <input
            type="text"
            name="name"
            value={part.name}
            onChange={handleChange}
            className="form-input"
          />
        </label>
        <label>
          ბრენდი
          <select
            name="brand"
            value={part.brand}
            onChange={handleChange}
            className="form-select"
          >
            <option value="">Select a brand</option>
            {Object.keys(carModels).map((brand) => (
              <option key={brand + uuidv4()} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </label>
        <label>
          მოდელი
          <select
            name="model"
            value={part.model}
            onChange={handleChange}
            className="form-select"
          >
            <option value="">Select a model</option>
            {part.brand &&
              carModels[part.brand].map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
          </select>
        </label>
        <label>
          ფასი
          <input
            type="text"
            name="price"
            value={part.price}
            onChange={handleChange}
            className="form-input"
          />
        </label>
        <label>
          სურათი
          <input
            type="file"
            name="image"
            onChange={handleImageChange}
            className="form-input"
          />
        </label>
        <label>
          მარაგშია:
          <input
            type="checkbox"
            name="inStock"
            checked={part.stock}
            onChange={(e) => setPart({ ...part, stock: e.target.checked })}
            className="form-input"
          />
        </label>
        <button type="submit" className="form-button">
          {part.id ? "Update" : "Add"}
        </button>
      </form>

      <table className="part-table">
        <thead>
          <tr>
            <th>სახელი</th>
            <th>მოდელი</th>
            <th>ფასი</th>
            <th>სურათი</th>
            <th>მარაგი</th>
            <th>რედაქტირება</th>
            <th>წაშლა</th>
          </tr>
        </thead>
        <tbody>
          {parts.map((part) => (
            <tr key={part.id}>
              <td>{part.name}</td>
              <td>{part.model}</td>
              <td>{part.price}</td>
              <td>
                <img
                  src={part.image_url}
                  alt=""
                  width="80px"
                  height="80px"
                  className="panel-image"
                />
              </td>
              <td>{part.stock ? "მარაგშია" : "არ არის მარაგში"}</td>
              
              <td>
                <button onClick={() => updatePart(part)} className="table-button">
                  Update
                </button>
              </td>
              <td>
                <button onClick={() => deletePart(part)} className="table-button">
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminPanel;
